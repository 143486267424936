.slick-slide>div {
    margin: 0 10px;
}

.slick-list {
    margin: 0 -10px;
}

.active-card {
    border: 2px solid #a32eff !important;
}