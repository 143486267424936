* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Encode Sans Expanded';
}

.home {
    overflow-x: clip;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: #010606;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #a32eff;
    border-radius: 10px;
}

::-webkit-scrollbar-corner {
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}